<template>
  <div>
    <b-row class="justify-content-center row-eq-height" v-if="!showTC">
      <b-col md="8" class="pr-1 pl-1">
        <b-card no-body class="mb-1">
          <b-card-body class="p-4">
            <h2>Register</h2>
            <p class="text-muted">Create your account and continue</p>
            <b-row>
              <b-col sm="12" md="6">
                <b-input-group>
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-user"></i
                    ></span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.user.firstName.$error,
                      'is-valid': !$v.user.firstName.$invalid
                    }"
                    placeholder="First Name"
                    v-model="user.firstName"
                    @blur="$v.user.firstName.$touch()"
                  />
                  <b-form-invalid-feedback v-if="!$v.user.firstName.required"
                    >First Name can't be blank</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-if="!$v.user.firstName.alphaNum"
                    >First Name can be only alphanumeric
                    characters</b-form-invalid-feedback
                  >
                  <!-- <b-form-invalid-feedback v-if="!$v.user.firstName.minLen"
                    >First Name must be of minimum 3 characters
                    length</b-form-invalid-feedback
                  > -->
                </b-input-group>
              </b-col>
              <div class="d-block d-md-none" v-html="'&nbsp;'"></div>
              <b-col sm="12" md="6" class="last-name">
                <b-input-group class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.user.lastName.$error,
                      'is-valid': !$v.user.lastName.$invalid
                    }"
                    placeholder="Last Name"
                    v-model="user.lastName"
                    @blur="$v.user.lastName.$touch()"
                  />
                  <b-form-invalid-feedback v-if="!$v.user.lastName.required"
                    >Last Name can't be blank</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-if="!$v.user.lastName.alphaNum"
                    >Last Name can be only alphanumeric
                    characters</b-form-invalid-feedback
                  >
                  <!-- <b-form-invalid-feedback v-if="!$v.user.lastName.minLen"
                    >Last Name must be of minimum 3 characters
                    length</b-form-invalid-feedback
                  > -->
                </b-input-group>
              </b-col>
            </b-row>

            <b-input-group class="mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
              <input
                type="email"
                class="form-control"
                :class="{
                  'is-invalid': $v.user.email.$error,
                  'is-valid': !$v.user.email.$invalid
                }"
                placeholder="Email"
                v-model="userEmail"
                @blur="$v.user.email.$touch()"
              />
              <b-form-invalid-feedback v-if="!$v.user.email.required"
                >Email can't be blank</b-form-invalid-feedback
              >
              <b-form-invalid-feedback v-if="!$v.user.email.email">
                Please provide a valid email address</b-form-invalid-feedback
              >
            </b-input-group>

            <b-input-group class="mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fa fa-key"></i></span>
              </div>
              <input
                type="password"
                class="form-control"
                :class="{
                  'is-invalid': $v.user.password.$error,
                  'is-valid': !$v.user.password.$invalid
                }"
                placeholder="Password"
                v-model="user.password"
                @blur="$v.user.password.$touch()"
              />
              <b-form-invalid-feedback v-if="!$v.user.password.required"
                >Password can't be blank</b-form-invalid-feedback
              >
              <b-form-invalid-feedback v-if="!$v.user.password.minLen"
                >Password must be of minimum 4 characters
                length</b-form-invalid-feedback
              >
            </b-input-group>
            <b-input-group class="mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fa fa-key"></i></span>
              </div>
              <input
                type="password"
                class="form-control"
                :class="{
                  'is-invalid': $v.user.repeat_password.$error,
                  'is-valid': !$v.user.repeat_password.$invalid
                }"
                placeholder="Repeat password"
                v-model="user.repeat_password"
                @blur="$v.user.repeat_password.$touch()"
              />
              <b-form-invalid-feedback v-if="!$v.user.repeat_password.sameAs"
                >Password and Repeated Password are not
                matching</b-form-invalid-feedback
              >
            </b-input-group>
            <b-input-group class="mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-institution"></i
                ></span>
              </div>
              <input
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': $v.user.company.$error,
                  'is-valid': !$v.user.company.$invalid
                }"
                placeholder="Company"
                v-model="user.company"
                @blur="$v.user.company.$touch()"
              />
              <b-form-invalid-feedback v-if="!$v.user.company.required"
                >Company can't be blank</b-form-invalid-feedback
              >
              <!-- <b-form-invalid-feedback v-if="!$v.user.company.minLen"
                >Company must be of minimum 3 characters
                length</b-form-invalid-feedback
              > -->
            </b-input-group>
            <b-input-group class="mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-crosshairs"></i
                ></span>
              </div>
              <multiselect
                v-model="user.companyType"
                :options="companyTypes"
                :multiple="false"
                :close-on-select="true"
                placeholder="Select Company Type"
                group-label="type"
                group-values="companies"
                :class="[
                  {
                    'is-invalid': $v.user.companyType.$error,
                    'is-valid': !$v.user.companyType.$invalid
                  },
                  'simple-select group-select highlight-group-title form-control'
                ]"
                :group-select="false"
                :showLabels="false"
                @close="validateCompanyType()"
              />
              <b-form-invalid-feedback v-if="!$v.user.companyType.required"
                >Company Type can't be blank</b-form-invalid-feedback
              >
            </b-input-group>
            <b-input-group class="mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-graduation-cap"></i
                ></span>
              </div>
              <input
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': $v.user.title.$error,
                  'is-valid': !$v.user.title.$invalid
                }"
                placeholder="Title"
                v-model="user.title"
                @blur="$v.user.title.$touch()"
              />
              <b-form-invalid-feedback v-if="!$v.user.title.required"
                >Title can't be blank</b-form-invalid-feedback
              >
              <!-- <b-form-invalid-feedback v-if="!$v.user.title.minLen"
                >Title must be of minimum 3 characters
                length</b-form-invalid-feedback
              > -->
            </b-input-group>
            <b-input-group class="mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-phone"></i
                ></span>
              </div>
              <cleave
                placeholder="Phone"
                :options="phoneOptions"
                v-model="user.phone"
                class="form-control"
                :class="{
                  'is-invalid': $v.user.phone.$error,
                  'is-valid': !$v.user.phone.$invalid
                }"
                @blur="$v.user.phone.$touch()"
              ></cleave>
              <b-form-invalid-feedback v-if="!$v.user.phone.required"
                >Phone can't be blank</b-form-invalid-feedback
              >
              <b-form-invalid-feedback
                v-if="!$v.user.phone.mustMatchPhoneLength"
                >Phone number is not valid.</b-form-invalid-feedback
              >
            </b-input-group>
            <b-input-group class="mb-3 terms-conditions">
              <b-form-checkbox
                id="terms"
                v-model="terms"
                checked
                @click="$v.terms.$touch()"
              >
                I agree to the
                <a href="javascript:void(0)" @click="toggleTermsConditions()"
                  >Terms of Service.</a
                >
              </b-form-checkbox>
              <b-form-invalid-feedback v-if="!$v.terms.required"
                >You must agree to the terms and
                conditions.</b-form-invalid-feedback
              >
            </b-input-group>
            <b-button
              variant="success"
              @click="signUpUser"
              block
              :disabled="$v.$invalid || submitting"
              >{{ submitText() }}</b-button
            >
          </b-card-body>
        </b-card>
        <div class="px-4 mb-2">
          Already have an account?
          <a href="#" @click="setSignIn"><b>Login</b></a>
        </div>
      </b-col>
    </b-row>
    <div v-if="showTC">
      <div class="modal-content">
        <b-card-header header-class="vendor-card">
          <h5 class="text-center"><b>TERMS OF SERVICE</b></h5>
        </b-card-header>
        <b-card-body>
          <TermsOfService></TermsOfService>
        </b-card-body>
      </div>
      <div slot="modal-footer" class="w-100 text-center mt-2">
        <button
          type="button"
          class="btn btn-secondary fw-btn"
          @click="showTC = false"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary fw-btn"
          @click="
            terms = true
            showTC = false
          "
        >
          Agree
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  required,
  email,
  minLength,
  alphaNum,
  sameAs
} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations } from 'vuex'
import TermsOfService from '@/modules/authentication/pages/terms_of_service'
import Multiselect from 'vue-multiselect'
import Cleave from 'vue-cleave-component'
import userAPI from '@/modules/core/api/user'
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  name: 'Register',
  components: {
    TermsOfService,
    Multiselect,
    Cleave
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeat_password: '',
        company: '',
        companyType: null,
        title: '',
        phone: ''
      },
      submitting: false,
      terms: true,
      showTC: false,
      phoneOptions: {
        phone: true,
        phoneRegionCode: 'US',
        delimiter: '-'
      }
    }
  },
  computed: {
    ...mapGetters(['companyTypes']),
    userEmail: {
      get() {
        return this.user.email
      },
      set(email) {
        this.user.email = email.toLowerCase()
      }
    }
  },
  methods: {
    ...mapMutations(['setRedirectUrl']),
    signUpUser: function() {
      if (this.user.phone.replaceAll('-', '').length > 11) {
        Vue.toasted.show('Please enter a valid Phone number', {
          icon: 'chain-broken',
          type: 'error'
        })
        return
      }
      this.submitting = true
      userAPI
        .createUser({ user: this.user, skipRedirect: true })
        .then(userCreated => {
          this.submitting = false
          if (userCreated) {
            this.setRedirectUrl(this.$route.fullPath)
            this.$emit('registered')
          }
        })
    },
    submitText: function() {
      return this.submitting ? 'Requesting ...' : 'Submit Request'
    },
    toggleTermsConditions: function() {
      this.showTC = !this.showTC
    },
    validateCompanyType() {
      this.$v.user.companyType.$touch()
    },
    setSignIn(evt) {
      evt.preventDefault()
      this.showTC = false
      this.$emit('update:selectedComponent', 'SignInModal')
    }
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
      firstName: {
        required,
        alphaNum
        // minLen: minLength(3)
      },
      lastName: {
        required,
        alphaNum
        // minLen: minLength(3)
      },
      password: {
        required,
        minLen: minLength(4)
      },
      repeat_password: {
        required,
        sameAs: sameAs(vm => {
          return vm.password
        })
      },
      company: {
        required
        // minLen: minLength(3)
      },
      companyType: {
        required
      },
      title: {
        required
        // minLen: minLength(3)
      },
      phone: {
        required,
        mustMatchPhoneLength(value) {
          if (value) {
            let phone = value.replaceAll('-', '')
            return phone.startsWith('1')
              ? phone.length === 11
              : phone.length === 10
          } else {
            return false
          }
        }
      }
    },
    terms: {
      required
    }
  }
}
</script>
