<template>
  <b-row class="justify-content-center row-eq-height">
    <b-col md="8" class="pr-1 pl-1">
      <b-card no-body>
        <b-card-body class="p-4">
          <h2>Login</h2>
          <p class="text-muted">Sign In to your account to continue</p>
          <b-input-group class="mb-4 pt-2">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fa fa-envelope"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control"
              :class="{
                'is-invalid': $v.user.email.$error,
                'is-valid': !$v.user.email.$invalid
              }"
              placeholder="Email"
              v-model="user.email"
              @blur="$v.user.email.$touch()"
            />
            <b-form-invalid-feedback
              v-if="!$v.user.email.required"
              class="within-input"
              >Email can't be blank</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              v-if="!$v.user.email.email"
              class="within-input"
            >
              Please provide a valid email address</b-form-invalid-feedback
            >
          </b-input-group>
          <b-input-group class="mb-4">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-key"></i></span>
            </div>
            <input
              type="password"
              class="form-control"
              :class="{
                'is-invalid': $v.user.password.$error,
                'is-valid': !$v.user.password.$invalid
              }"
              placeholder="Password"
              v-model="user.password"
              @keyup.enter="loginSubmit()"
              @blur="$v.user.password.$touch()"
            />
            <b-form-invalid-feedback
              v-if="!$v.user.password.required"
              class="within-input"
              >Password can't be blank</b-form-invalid-feedback
            >
          </b-input-group>
          <b-row class="mb-4">
            <b-col cols="8" class="align-self-end">
              <div class="">
                Don't have an account?
                <a href="#" @click="setSignUp"><b>Sign-up</b></a>
              </div>
            </b-col>
            <b-col cols="4" class="text-right">
              <b-button
                variant="primary"
                class="px-4 login-btn"
                :disabled="$v.user.$invalid || submitting"
                @click="loginSubmit"
              >
                {{ submitText() }}</b-button
              >
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: {
        email: '',
        password: ''
      },
      submitting: false
    }
  },
  computed: {
    ...mapGetters('Authentication', ['currentUserEmail', 'userName']),
    ...mapGetters(['redirectUrl'])
  },
  methods: {
    ...mapActions('Authentication', ['logIn']),
    loginSubmit: function() {
      this.submitting = true
      this.logIn({ user: this.user, skipRedirect: true }).then(() => {
        this.submitting = false
        if (this.currentUserEmail) {
          this.$toasted.show('Logged in as ' + this.userName, {
            icon: 'user-circle',
            type: 'success'
          })
          this.$emit('loggedIn')
        }
      })
    },
    submitText: function() {
      return this.submitting ? 'Logging...' : 'Login'
    },
    setSignUp(evt) {
      evt.preventDefault()
      this.$emit('update:selectedComponent', 'SignUpModal')
    }
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  }
}
</script>
