<template>
  <b-modal
    id=""
    size="lg"
    hide-header
    v-model="show"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <button
      type="button"
      aria-label="Close"
      class="close pb-1"
      @click="closeModal"
      title="Close"
    >
      ×
    </button>
    <component
      v-bind:is="selectedComponent"
      v-bind:selectedComponent.sync="selectedComponent"
      @loggedIn="loggedIn"
      @registered="registered"
    ></component>
  </b-modal>
</template>

<script>
import SignInModal from './SignInModal'
import SignUpModal from './SignUpModal'

export default {
  name: 'UserAuthenticationModal',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SignUpModal,
    SignInModal
  },
  data() {
    return {
      selectedComponent: 'SignInModal'
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeAuthModal')
    },
    loggedIn() {
      this.$emit('loginSuccess')
    },
    registered() {
      this.closeModal()
    }
  }
}
</script>
