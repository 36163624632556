var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.showTC)?_c('b-row',{staticClass:"justify-content-center row-eq-height"},[_c('b-col',{staticClass:"pr-1 pl-1",attrs:{"md":"8"}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"p-4"},[_c('h2',[_vm._v("Register")]),_c('p',{staticClass:"text-muted"},[_vm._v("Create your account and continue")]),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-input-group',[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-user"})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.firstName),expression:"user.firstName"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.user.firstName.$error,
                    'is-valid': !_vm.$v.user.firstName.$invalid
                  },attrs:{"type":"text","placeholder":"First Name"},domProps:{"value":(_vm.user.firstName)},on:{"blur":function($event){return _vm.$v.user.firstName.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "firstName", $event.target.value)}}}),(!_vm.$v.user.firstName.required)?_c('b-form-invalid-feedback',[_vm._v("First Name can't be blank")]):_vm._e(),(!_vm.$v.user.firstName.alphaNum)?_c('b-form-invalid-feedback',[_vm._v("First Name can be only alphanumeric characters")]):_vm._e()],1)],1),_c('div',{staticClass:"d-block d-md-none",domProps:{"innerHTML":_vm._s('&nbsp;')}}),_c('b-col',{staticClass:"last-name",attrs:{"sm":"12","md":"6"}},[_c('b-input-group',{staticClass:"mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.lastName),expression:"user.lastName"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.user.lastName.$error,
                    'is-valid': !_vm.$v.user.lastName.$invalid
                  },attrs:{"type":"text","placeholder":"Last Name"},domProps:{"value":(_vm.user.lastName)},on:{"blur":function($event){return _vm.$v.user.lastName.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "lastName", $event.target.value)}}}),(!_vm.$v.user.lastName.required)?_c('b-form-invalid-feedback',[_vm._v("Last Name can't be blank")]):_vm._e(),(!_vm.$v.user.lastName.alphaNum)?_c('b-form-invalid-feedback',[_vm._v("Last Name can be only alphanumeric characters")]):_vm._e()],1)],1)],1),_c('b-input-group',{staticClass:"mb-3"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-envelope"})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userEmail),expression:"userEmail"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.user.email.$error,
                'is-valid': !_vm.$v.user.email.$invalid
              },attrs:{"type":"email","placeholder":"Email"},domProps:{"value":(_vm.userEmail)},on:{"blur":function($event){return _vm.$v.user.email.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.userEmail=$event.target.value}}}),(!_vm.$v.user.email.required)?_c('b-form-invalid-feedback',[_vm._v("Email can't be blank")]):_vm._e(),(!_vm.$v.user.email.email)?_c('b-form-invalid-feedback',[_vm._v(" Please provide a valid email address")]):_vm._e()],1),_c('b-input-group',{staticClass:"mb-3"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-key"})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.user.password.$error,
                'is-valid': !_vm.$v.user.password.$invalid
              },attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.user.password)},on:{"blur":function($event){return _vm.$v.user.password.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}}),(!_vm.$v.user.password.required)?_c('b-form-invalid-feedback',[_vm._v("Password can't be blank")]):_vm._e(),(!_vm.$v.user.password.minLen)?_c('b-form-invalid-feedback',[_vm._v("Password must be of minimum 4 characters length")]):_vm._e()],1),_c('b-input-group',{staticClass:"mb-3"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-key"})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.repeat_password),expression:"user.repeat_password"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.user.repeat_password.$error,
                'is-valid': !_vm.$v.user.repeat_password.$invalid
              },attrs:{"type":"password","placeholder":"Repeat password"},domProps:{"value":(_vm.user.repeat_password)},on:{"blur":function($event){return _vm.$v.user.repeat_password.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "repeat_password", $event.target.value)}}}),(!_vm.$v.user.repeat_password.sameAs)?_c('b-form-invalid-feedback',[_vm._v("Password and Repeated Password are not matching")]):_vm._e()],1),_c('b-input-group',{staticClass:"mb-3"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-institution"})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.company),expression:"user.company"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.user.company.$error,
                'is-valid': !_vm.$v.user.company.$invalid
              },attrs:{"type":"text","placeholder":"Company"},domProps:{"value":(_vm.user.company)},on:{"blur":function($event){return _vm.$v.user.company.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "company", $event.target.value)}}}),(!_vm.$v.user.company.required)?_c('b-form-invalid-feedback',[_vm._v("Company can't be blank")]):_vm._e()],1),_c('b-input-group',{staticClass:"mb-3"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-crosshairs"})])]),_c('multiselect',{class:[
                {
                  'is-invalid': _vm.$v.user.companyType.$error,
                  'is-valid': !_vm.$v.user.companyType.$invalid
                },
                'simple-select group-select highlight-group-title form-control'
              ],attrs:{"options":_vm.companyTypes,"multiple":false,"close-on-select":true,"placeholder":"Select Company Type","group-label":"type","group-values":"companies","group-select":false,"showLabels":false},on:{"close":function($event){return _vm.validateCompanyType()}},model:{value:(_vm.user.companyType),callback:function ($$v) {_vm.$set(_vm.user, "companyType", $$v)},expression:"user.companyType"}}),(!_vm.$v.user.companyType.required)?_c('b-form-invalid-feedback',[_vm._v("Company Type can't be blank")]):_vm._e()],1),_c('b-input-group',{staticClass:"mb-3"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-graduation-cap"})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.title),expression:"user.title"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.user.title.$error,
                'is-valid': !_vm.$v.user.title.$invalid
              },attrs:{"type":"text","placeholder":"Title"},domProps:{"value":(_vm.user.title)},on:{"blur":function($event){return _vm.$v.user.title.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "title", $event.target.value)}}}),(!_vm.$v.user.title.required)?_c('b-form-invalid-feedback',[_vm._v("Title can't be blank")]):_vm._e()],1),_c('b-input-group',{staticClass:"mb-3"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-phone"})])]),_c('cleave',{staticClass:"form-control",class:{
                'is-invalid': _vm.$v.user.phone.$error,
                'is-valid': !_vm.$v.user.phone.$invalid
              },attrs:{"placeholder":"Phone","options":_vm.phoneOptions},on:{"blur":function($event){return _vm.$v.user.phone.$touch()}},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),(!_vm.$v.user.phone.required)?_c('b-form-invalid-feedback',[_vm._v("Phone can't be blank")]):_vm._e(),(!_vm.$v.user.phone.mustMatchPhoneLength)?_c('b-form-invalid-feedback',[_vm._v("Phone number is not valid.")]):_vm._e()],1),_c('b-input-group',{staticClass:"mb-3 terms-conditions"},[_c('b-form-checkbox',{attrs:{"id":"terms","checked":""},on:{"click":function($event){return _vm.$v.terms.$touch()}},model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}},[_vm._v(" I agree to the "),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.toggleTermsConditions()}}},[_vm._v("Terms of Service.")])]),(!_vm.$v.terms.required)?_c('b-form-invalid-feedback',[_vm._v("You must agree to the terms and conditions.")]):_vm._e()],1),_c('b-button',{attrs:{"variant":"success","block":"","disabled":_vm.$v.$invalid || _vm.submitting},on:{"click":_vm.signUpUser}},[_vm._v(_vm._s(_vm.submitText()))])],1)],1),_c('div',{staticClass:"px-4 mb-2"},[_vm._v(" Already have an account? "),_c('a',{attrs:{"href":"#"},on:{"click":_vm.setSignIn}},[_c('b',[_vm._v("Login")])])])],1)],1):_vm._e(),(_vm.showTC)?_c('div',[_c('div',{staticClass:"modal-content"},[_c('b-card-header',{attrs:{"header-class":"vendor-card"}},[_c('h5',{staticClass:"text-center"},[_c('b',[_vm._v("TERMS OF SERVICE")])])]),_c('b-card-body',[_c('TermsOfService')],1)],1),_c('div',{staticClass:"w-100 text-center mt-2",attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary fw-btn",attrs:{"type":"button"},on:{"click":function($event){_vm.showTC = false}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-primary fw-btn",attrs:{"type":"button"},on:{"click":function($event){_vm.terms = true
          _vm.showTC = false}}},[_vm._v(" Agree ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }